import {functionComponent} from "../utils";
import * as React from "react";
import {StaticImage} from "gatsby-plugin-image";
import {Link} from "gatsby";

export default functionComponent(({children}) =>
    <section id="intro" className="bg-secondary pt-5 pb-sm-5">
        <div className="container-lg my-sm-5">
            <div className="row justify-content-center">
                <div className="col-12 col-sm-7 col-xl-5 mb-3 mb-sm-0">
                    <p className="lead">Verander van binnen naar buiten</p>
                    <h1 className="display-3">Leven vanuit rust, kracht en zelfverzekerdheid</h1>
                    <p className="fw-bold my-4">Hoe zou je leven er uit zien zonder overheersende twijfels, angsten en onzekerheden? Laat me je inspireren of vraag een vrijblijvend adviesgesprek aan en zie wat ik voor je kan betekenen.</p>
                    <Link className="btn btn-primary d-inline-block me-2" role="button" to="/reading">persoonlijke reading</Link>
                    <Link className="btn btn-outline-dark d-inline-block" to="#contact" role="button">kom in contact</Link>
                </div>
                <div className="col-8 col-sm-5 d-flex justify-content-center align-items-center">
                    <StaticImage alt="Foto van Petra Koppers" className="col col-xl-9" src="../images/petra_8.jpeg" />
                </div>
            </div>
        </div>
    </section>
)