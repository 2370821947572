import {functionComponent} from "../utils";
import * as React from "react";
import {FunctionComponent} from "react";


export default functionComponent(props =>
    <section id="features">
        <div className="container-lg py-5">
            <div className="row justify-content-center">
                <div className="col-fluid col-md-5">
                    <h4 className="display-4">Jij bent…</h4>
                    <p>
                        …lief, integer, zorgend, gevoelig maar ook ben je bent heel sterk. Je bent een doorzetter. Je staat altijd klaar voor anderen en verliest daarbij soms jezelf. Je vraagt veel van jezelf maar toch is de onzekerheid er of je het wel goed genoeg doet, of je wel goed genoeg bent. Je ben sfeergevoelig, voelt situaties goed aan en je kunt je goed inleven. Ondanks dat je moe, gestrest of misschien wel uitgeput bent blijf je door gaan. Hierdoor geef je steeds wat meer dan dat je te geven hebt, en brand je langzaam op.
                    </p>
                    <p>
                        Je wilt je weer vol energie, blij, gelukkig en voldaan voelen en je bent hierdoor aan het doen, doen, doen – maar zonder resultaat. Je écht ontspannen en alles even van je afzetten lukt niet meer, terwijl dat juist is wat je nodig hebt.
                    </p>
                </div>
                <div className="col-fluid col-md-7 d-flex align-items-center justify-content-center">
                    <Card/>
                </div>
                <div className="col-12 col-sm-10 col-md-7 col-lg-6 py-5 mt-5 text-center">
                    <span className="bi bi-heart text-danger h1"/>
                    <p className="lead">
                        Jouw sensitiviteit is  prachtige eigenschap. Hoe meer je de negatieve kant kunt ombuigen naar krachten en kwaliteiten, hoe meer balans hierin komt; hoe meer jij kunt gaan genieten.
                    </p>
                    <p className="lead">
                        Want hoe diep jij de low’s kunt ervaren, even diep en intens kan je alle mooie momenten ervaren.
                    </p>
                    <p className="lead">
                        En hoe meer van de emotionele lading we voor jou gaan weghalen, hoe mooier en mooier jouw leven gaat worden.
                    </p>
                </div>
            </div>
        </div>
    </section>
)

const Card: FunctionComponent = () =>
    <div className="card shadow border-0 w-75">
        <div className="card-body">
            <p className="card-title fw-bold">
                Jij hebt een hele intense belevings- en gevoelswereld. Misschien denk je dat iedereen dat heeft, maar dat is niet het geval. Wat jou typeert als gevoelig persoon:
            </p>
            <ul className="card-text list-unstyled">
                <li><i className="bi bi-check2 me-2"/>je bent een natural pleaser en gefocust op wat andere mensen nodig hebben</li>
                <li><i className="bi bi-check2 me-2"/>je trekt je veel aan van dingen die mensen tegen je zeggen en betrekt dingen snel op jezelf</li>
                <li><i className="bi bi-check2 me-2"/>spanning, negativiteit en onenigheid raakt je diep</li>
                <li><i className="bi bi-check2 me-2"/>je voelt je vaak niet goed begrepen (en dit kan soms eenzaam voelen)</li>
                <li><i className="bi bi-check2 me-2"/>je wilt aan alle verwachtingen voldoen en als het niet lukt, voelt het als falen (faalangst)</li>
            </ul>
        </div>
    </div>;