import {functionComponent} from "../utils";
import * as React from "react";


export default functionComponent(props =>
    <section id="description" className="py-5">
        <div className="container-lg">
            <div className="row justify-content-center">
                <div className="col-12 col-md-9 col-lg-7">
                    <h2>Verlichting voor onzekere vrouwen</h2>
                    <p className="lead">
                        Ben je al lange tijd zoekende naar een manier om met je gevoeligheid en onzekerheden om te gaan? Heb je van alles geprobeerd en vraag je je af waarom je nog steeds met dezelfde thema’s aan het werk bent en je niet eerder de resultaten hebt gekregen (of weten te behouden) waar je zo je best voor doet? Misschien ben je bang dat het aan jou ligt, want je hebt tenslotte al van alles geprobeerd, maar iets in jou hoopt en gelooft dat het anders kan. En daarom ben je nu hier.
                    </p>
                    <h4>Speciaal voor vrouwen. Waarom?</h4>
                    <p>
                        Van nature reageren we op situaties vanuit gevoel, niet vanuit logica. We leven nu in een maatschappij waar we veel met ons hoofd doen en niet meer goed in verbinding staan met ons gevoel. Dit maakt dat we over-focussed, gestrest en gespannen zijn.
                    </p>
                    <p>
                        We zijn gevoelsmensen en tegen beter weten in proberen we ons een weg uit onze problemen te denken. Maar als we onze problemen met alleen logisch denken op konden lossen, dan zouden we niet zo vast zitten. Denken lost emotionele problemen niet op, maar houdt ons juist vast in de gedachten spiraal. Dit zorgt ervoor dat we niet meer kunnen stoppen met denken wat vervolgens lijdt naar nog meer spanning, zorgen, overprestatie, zelfkritiek en faalpatronen.
                    </p>
                    <h4>Je weet dat je het anders wilt, maar je weet niet hoe</h4>
                    <p>
                        Mijn expertise ligt bij het op een eenvoudige wijze transformeren van twijfels, onzekerheden en angsten die in het systeem van iedere vrouw opgeslagen liggen.
                    </p>
                </div>
            </div>
        </div>
    </section>
)