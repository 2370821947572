import * as React from "react"
import {functionComponent} from "../utils";
import * as $ from "jquery"
import * as classNames from "classnames";

const data = [
    {
        quote: "Petra gaf mij meteen een vertrouwd gevoel, ze straalt zoveel rust uit. Tijdens de sessies merkte ik dat ze erg goed kan luisteren en daardoor meteen je pijnpunt (issue, patroon) weet te vinden. Waar je vervolgens ook meteen van af komt, wat een bevrijding geeft dat!! Petra en de methode hebben mijn leven veranderd. Ik had al zoveel dingen geprobeerd qua therapie en coaching, maar telkens leek ik terug te vallen in mijn oude patronen. Maar Petra en de methode hebben ervoor gezorgd dat ik het echt achter me kon laten. Ik had daarvoor nog steeds zo'n last van weinig zelfvertrouwen dat die negatieve stemmetjes maar in mijn hoofd bleven rond gaan. Stemmetjes als dat kun je toch niet, je bent niet goed genoeg, de ander kan dat zoveel beter, doe geen moeite. En naast deze en nog meer stemmen had ik ook aardig wat angsten die mij in de weg zaten. Ik ben zo blij en opgelucht dat die weg zijn. Het geeft zo'n vrij gevoel. Het is ook een hele prettige methodiek, je hoeft je pijn helemaal niet te ervaren en toch ben je na de sessies van die pijn die je eerder voelde verlost. Ik kan alle vrouwen aanraden die nu voor eens en altijd van die negatieve stemmen in hun hoofd verlost willen worden!!",
        name: "Tamara"
    },
    {
        quote: "Dankjewel voor je liefdevolle benadering naar mij. Je ondersteuning bij mijn reis zodat ik doorbraken mocht beleven. Ik kan weer met liefde aan mijn zoon denken. Dank je wel hiervoor.",
        name: "W."
    },
    {
        quote: "Thank you Petra, for the wonderful session. I’ve got fabulously beautiful learnings, went through them like a train… thank you again for spotting my blockage… I feel possibilities and options now instead of a stand-still.",
        name: "Irene"
    },
    {
        quote: "Petra heeft een shift teweeggebracht in mijn bewustzijn aangaande een bepaalde emotie die me geen keuze leek te geven. Door deze sessie kon ik me weer vrij maken en zien dat er veel prettigere mogelijkheden zijn met veel betere uitkomsten. Hartelijk bedankt daarvoor, ik voel me veel meer ontspannen nu!",
        name: "A. Vos"
    },
    {
        quote: "Petra heeft me ongelooflijk goed geholpen om een emotie los te laten die me al jaren in de weg stond. Mijn teleurstelling over een aantal dingen in mijn leven zorgden ervoor dat ik ongelooflijk kwaad was, op mezelf en de wereld om me heen, en mezelf niet kon motiveren stappen te ondernemen om dingen te veranderen. Petra kwam al snel tot de kern van het probleem en ik voelde me erg bij haar op mijn gemak. De methode die ze gebruikt is heel prettig en gaf me zoveel mooie inzichten in mezelf en mijn potentieel. Sinds de sessie voel ik me energieker en sterker!",
        name: "Hester"
    },
    {
        quote: "Dank je wel Petra voor deze waardevolle reis!! Het levert zoveel op! Super bedankt daarvoor!",
        name: "Linda"
    },
    {
        quote: "Een ongelooflijke mega dikke dankjewel voor me te laten inzien dat 'ik ben het niet waard' totaal overbodig is. Door jouw tijd, rust en vertrouwen tijdens onze sessie hebben we een gigantische doorbraak gerealiseerd. \n Ik voel me zoveel rustiger en klaar voor groei.",
        name: "Anita"
    },
    {
        quote: "De rust die Petra uitstraalt wekt onmiddellijk vertrouwen op. Ze luistert heel intens en komt snel tot de kern. Je bent direct op je gemak. Op slechts één sessie ben ik al verlost van diepe halsstarrige issues. Dank je Petra. ❤",
        name: "C. Janssen"
    }
]

export default functionComponent(() =>
    <section id="testimonials" className="bg-light">
        <div className="container-lg py-5">
            <div className="row justify-content-center">
                <div className="col-auto col-sm-9 col-lg-8">
                    <div className="text-center">
                        <h2>Ervaringen</h2>
                        <p className="lead text-muted">Wat anderen over hun ervaring vertellen</p>
                    </div>
                    <Carousel reviews={data}/>
                </div>
            </div>
        </div>
    </section>
)

class Carousel extends React.Component<any, { reviews: { quote: string, name: string }[] }> {

    private readonly carouselRef: React.RefObject<HTMLDivElement>;
    private disconnectObservers: () => void;

    constructor(props) {
        super(props);
        this.state = {reviews: data}
        this.carouselRef = React.createRef()
    }

    render() {
        return <div id="testimonialsCarousel" className="carousel slide" ref={this.carouselRef}>
            <div className="carousel-inner px-5">
                {this.state.reviews.map((review, index) =>
                    <div key={index} className={classNames("carousel-item px-0 px-md-5", { active: index == 0 })}>
                        <blockquote className="p">
                            {review.quote}
                        </blockquote>
                        <p className="blockquote-footer">{review.name}</p>
                    </div>
                )}
            </div>
            <button className="carousel-control-prev" type="button"
                    data-bs-target="#testimonialsCarousel"
                    data-bs-slide="prev">
                <span className="bi bi-arrow-left-square-fill text-dark h1" aria-hidden="true"/>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button"
                    data-bs-target="#testimonialsCarousel"
                    data-bs-slide="next">
                <span className="bi bi-arrow-right-square-fill text-dark h1" aria-hidden="true"/>
                <span className="visually-hidden">Next</span>
            </button>
        </div>;
    }

    componentDidMount() {
        const target = this.carouselRef.current;
        const invalidateHeight = Carousel.invalidateHeightOf(target)
        const resizeObserver = new ResizeObserver(invalidateHeight)
        const mutationObserver = new MutationObserver(invalidateHeight)

        resizeObserver.observe(target)
        mutationObserver.observe(target, {attributes: false, childList: true, subtree: true})

        this.disconnectObservers = () => {
            resizeObserver.disconnect()
            mutationObserver.disconnect()
        }

        invalidateHeight()
    }

    componentWillUnmount() {
        this.disconnectObservers()
    }

    private static invalidateHeightOf: (target: HTMLElement) => () => void =
        target => () => {
            const carousel = $(target)
            const items = carousel.find("> .carousel-inner:first > .carousel-item")
            const activeIndex = items.index(".active")

            items.addClass("active")

            const height = items
                .map((i, e) => $(e).outerHeight(true))
                .toArray()
                .reduce((l, r) => Math.max(l, r), 0)

            carousel.innerHeight(height)
            items.removeClass("active")
            items.eq(activeIndex).addClass("active")
        }


}
