import * as React from "react"
import {Container} from "../components/Container";
import NavigationBar from "../components/NavigationBar";
import Contact from "../components/Contact";
import Banner from "../components/Banner";
import About from "../components/About";
import Intro from "../components/Intro";
import Features from "../components/Features";
import Testimonials from "../components/Testimonials";
import SEO from "../components/SEO";

export default function () {
    return <Container>
        <NavigationBar/>
        <Banner/>
        <Intro/>
        <About/>
        <Features/>
        <Testimonials/>
        <Contact/>
    </Container>
}

export const Head = () => <SEO />