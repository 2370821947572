import * as React from "react";

import {functionComponent} from "../utils";
import {Link} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";

export default functionComponent(() =>
    <section id="about" className="bg-light py-5">
        <div className="container-lg justify-content-center">
            <div className="row">
                <div className="col-5 d-none d-md-flex justify-content-center align-items-center">
                    <StaticImage alt="Foto van Petra Koppers" className="col-12 col-lg-9 col-xl-7" src="../images/petra_9.png" />
                </div>
                <div className="col-12 col-md-7">
                    <h3>Hoi, ik ben Petra</h3>

                    <div className="col d-flex d-md-none justify-content-center align-items-center">
                        <StaticImage alt="Foto van Petra Koppers" className="col-6 col-sm-4" src="../images/petra_9.png" />
                    </div>
                    <p>
                        Ontstaan vanuit vele interne conflicten en een zoektocht naar meer, heb ik wat moois gevonden. En dit wil ik graag met je delen.
                    </p>
                    <p>
                        Als vrouw ben ik (net als jij) “gespecialiseerd” in vrouw-zijn. En door mijn vele onderzoeken en studies ben ik gespecialiseerd in het vrouwen-brein. Als vrouw kan ik me makkelijker identificeren met problemen en issues waar vrouwen tegenaan lopen (onzekerheden, laag zelfbeeld, twijfels, etc.) met gevoelens die misschien niet altijd even logisch (te verklaren) zijn, maar wel heel zwaar op jouw leven kunnen drukken.
                    </p>

                    <p>
                        Door zelf deze weg te hebben bewandeld weet ik hoe moeilijk het is om te veranderen, ongeacht hoe hard je ook je best doet en hoe graag je ook wilt. Ik weet hoe het voelt. Zo lang je zoekende bent en niet weet wat je kan doen wat jou gaat helpen en je steeds terugvalt in het oude bekende, dan blijft het een lange weg die niet gemakkelijk is en niet fijn voelt. Maar dit hoeft niet. Je hoeft er niet langer mee te worstelen. Als jij er klaar voor bent, help ik jou hier graag bij.
                    </p>
                    <Link className="btn btn-primary d-inline-block me-2" to="#contact" role="button">kom in contact</Link>
                    <Link className="btn btn-outline-primary d-inline-block" to="/over-mij" role="button">volledige bio</Link>
                </div>
            </div>
        </div>
    </section>
)